<script>
import Layout from '@layouts/auth'
import { authMethods } from '@state/helpers'
import swalFeedback from '@src/mixins/swalFeedback.vue'

/**
 * Login component
 */
export default {
    components: { Layout },
    mixins: [swalFeedback],
    data() {
        return {
            email: '',
            password: '',
            tryingToLogIn: false,
        }
    },
    computed: {
        i18nPassword() {
            return this.getI18n('COMMON', 'password')
        },
        i18nLogin() {
            return this.getI18n('COMMON', 'login')
        },
        i18nForgotYourPassword() {
            return this.getI18n('COMMON', 'forgot_your_password')
        },
        placeholders() {
            return process.env.NODE_ENV === 'production'
                ? {}
                : {
                      email: 'Use "admin" to log in with the mock API',
                      password: 'Use "password" to log in with the mock API',
                  }
        },
    },
    methods: {
        ...authMethods,
        // Try to log the user in with the email
        // and password they provided.
        tryToLogIn() {
            this.tryingToLogIn = true
            return this.logIn({
                email: this.email,
                password: this.password,
            })
                .then((response) => {
                    this.getDefaultDashboardRole()
                    this.tryingToLogIn = false
                    if (response.tenant_id === "admin") {
                        this.$router.push({ name: 'tenant' })
                    }
                    else if (response.user && response.user.roles && response.user.roles.length > 0 && response.user.roles[0].name === 'cliente') {
                        this.$router.push(
                            this.$route.query.redirectFrom || { name: 'photoGallery' }
                        )
                    }
                    else {
                        this.$router.push(
                            this.$route.query.redirectFrom || { name: 'home' }
                        )
                    }
                })
                .catch((error) => {
                    this.tryingToLogIn = false
                    const statusCode = error.status
                    if (statusCode === 401 || statusCode === 422) {
                        this.warningFeedback('Login', this.getI18n('ERROR_CODES', 'invalid_login'))
                    } else {
                        this.dangerFeedback('Login', error ? error.data.message : this.getI18n('ERROR_CODES', 'invalid_login'))
                    }
                })
        },
    },
}
</script>

<template>
    <Layout>
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="d-flex">
                    <div class="card-body p-3">
                        <div class="text-center w-100 m-auto">
                            <a href="/">
                                <span>
                                    <img
                                        class="img-fluid"
                                        src="@assets/images/logo-login.png"
                                        alt
                                        height="116"
                                    />
                                </span>
                            </a>
                        </div>
                        <b-form
                            class="form-access"
                            @submit.prevent="tryToLogIn"
                        >
                            <b-form-group
                                id="input-group-1"
                                label="E-mail"
                                label-for="input-1"
                            >
                                <b-form-input
                                    id="input-1"
                                    v-model="email"
                                    type="email"
                                    required
                                    :disabled="tryingToLogIn"
                                    autocomplete="email"
                                    placeholder="Email"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="input-group-2"
                                :label="i18nPassword"
                                label-for="input-2"
                            >
                                <b-form-input
                                    id="input-2"
                                    v-model="password"
                                    type="password"
                                    required
                                    :disabled="tryingToLogIn"
                                    autocomplete="current-password"
                                    :placeholder="i18nPassword"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group id="button-group" class="mt-4">
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    class="btn-block"
                                    :disabled="tryingToLogIn"
                                >
                                    <template v-if="tryingToLogIn">
                                        <b-spinner small />
                                    </template>
                                    <template v-else>
                                        <span>
                                            {{ i18nLogin }}
                                        </span>
                                    </template>
                                </b-button>
                            </b-form-group>
                        </b-form>
                    </div>
                    <!-- end card-body -->
                </div>
                <!-- end card -->

                <div class="row">
                    <div class="col-12 text-center">
                        <p>
                            <router-link
                                v-slot="{ navigate }"
                                to="/forget-password"
                                custom
                            >
                                <a
                                    class="text-white"
                                    style="cursor: pointer"
                                    role="link"
                                    @click="navigate"
                                    >
                                {{ i18nForgotYourPassword }}?
                                </a>
                            </router-link>
                        </p>
                    </div>
                    <!-- end col -->
                </div>
                <!-- end row -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>
</template>
